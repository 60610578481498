var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "flex flex-column justify-between br2 light-gray",
      class: {
        "podcast-card-large": _vm.isLargeCard,
        "podcast-card-small mr3": !_vm.isLargeCard,
        "ph3 pv3 bg-adori-light-gray ba b--adori-gray ma2":
          !_vm.publish || _vm.isLargeCard,
      },
    },
    [
      _c("div", { class: _vm.isLargeCard ? "flex justify-between" : "" }, [
        _c("div", { staticClass: "w-100 h-100" }, [
          _c(
            "div",
            {
              staticClass: "title flex justify-between items-top pa1 h-100",
              class: _vm.isLargeCard ? "mb3" : "",
              staticStyle: { width: "97%" },
              on: {
                click: function ($event) {
                  return _vm.handlePodcastSelect()
                },
              },
            },
            [
              !_vm.publish || _vm.isLargeCard
                ? _c(
                    "div",
                    { staticClass: "w-100 h-100 flex dim-custom br2" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "bg-black br2 tc pointer",
                          class: _vm.isLargeCard ? "mr2" : "dim",
                          style: { width: "10rem", height: "10rem" },
                        },
                        [
                          _vm.rssFeedsByUid[_vm.rssFeedUid].squareImageUrl
                            ? _c("img", {
                                style: { width: "10rem", height: "10rem" },
                                attrs: {
                                  src: _vm.rssFeedsByUid[_vm.rssFeedUid]
                                    .squareImageUrl,
                                },
                              })
                            : _c(
                                "i",
                                {
                                  staticClass:
                                    "material-icons light-gray f1 br2 bg-adori-very-light-gray w2 mr3 v-mid",
                                },
                                [_vm._v("audiotrack")]
                              ),
                        ]
                      ),
                      _vm.isLargeCard
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "pointer h-100 flex items-start flex-column w-100 pr3 pv2",
                              staticStyle: { width: "50rem", height: "10rem" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "truncate pointer underline f4 mb4",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handlePodcastSelect()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.rssFeedsByUid[_vm.rssFeedUid].name
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "block-with-text fw1 pointer f5",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handlePodcastSelect()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.rssFeedDescription) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  )
                : _c(
                    "div",
                    { staticClass: "w-100 h-100 flex dim-custom br2" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "bg-black br3 tc pointer",
                          class: _vm.isLargeCard ? "mr2" : "dim",
                          style: { width: "15rem", height: "12rem" },
                        },
                        [
                          _vm.rssFeedsByUid[_vm.rssFeedUid].squareImageUrl
                            ? _c("img", {
                                staticClass: "br3 img",
                                style: { width: "15rem", height: "12rem" },
                                attrs: {
                                  src: _vm.rssFeedsByUid[_vm.rssFeedUid]
                                    .squareImageUrl,
                                },
                              })
                            : _c(
                                "i",
                                {
                                  staticClass:
                                    "material-icons light-gray f1 br2 bg-adori-very-light-gray w2 mr3 v-mid",
                                },
                                [_vm._v("audiotrack")]
                              ),
                        ]
                      ),
                    ]
                  ),
            ]
          ),
          _vm.isLargeCard
            ? _c("div", {
                staticClass: "podcast",
                on: {
                  click: function ($event) {
                    return _vm.handlePodcastSelect()
                  },
                },
              })
            : _vm._e(),
        ]),
        !_vm.isLargeCard && !_vm.publish
          ? _c("div", { staticClass: "truncate underline f5 mh2 mv3 tc" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.rssFeedsByUid[_vm.rssFeedUid].name) +
                  "\n    "
              ),
            ])
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "flex justify-center",
            style: _vm.isLargeCard ? "float: right;" : "",
          },
          [
            _vm.$permissions.isEditEpisodeShowAllowed(_vm.rssFeedUid) &&
            (!_vm.publish || _vm.isLargeCard)
              ? _c(
                  "div",
                  {
                    staticClass: "f4 fw1 pointer dim mr2",
                    attrs: { title: "Edit podcast" },
                    on: {
                      click: function ($event) {
                        return _vm.handleEditPodcast(_vm.rssFeedUid)
                      },
                    },
                  },
                  [
                    _c(
                      "i",
                      {
                        staticClass:
                          "ph2 pv2 br2 ba b--adori-gray light-gray bg-dark-gray btn-shadow material-icons f4 light-gray mr1 v-btm pointer",
                      },
                      [_vm._v("edit")]
                    ),
                  ]
                )
              : _vm._e(),
            !_vm.isLargeCard &&
            _vm.$permissions.isCreateEpisodeShowAllowed(_vm.rssFeedUid) &&
            (!_vm.publish || _vm.isLargeCard)
              ? _c(
                  "div",
                  {
                    staticClass: "f4 fw1 pointer dim",
                    attrs: { title: "Upload a new episode" },
                    on: {
                      click: function ($event) {
                        return _vm.handleAddEpisodePodcast()
                      },
                    },
                  },
                  [
                    _c(
                      "i",
                      {
                        staticClass:
                          "ph2 pv2 br2 ba b--adori-gray light-gray bg-adori-red btn-shadow material-icons f4 light-gray mr1 v-btm pointer",
                      },
                      [_vm._v("add")]
                    ),
                  ]
                )
              : _vm._e(),
          ]
        ),
      ]),
      _vm.isLargeCard
        ? _c(
            "div",
            { staticClass: "pt3 bt bw1 b--adori-gray" },
            [
              _c(
                "div",
                {
                  staticClass: "flex justify-start items-center mt2",
                  staticStyle: { float: "left" },
                },
                [
                  _c("div", { staticClass: "f6 fw1" }, [
                    _vm._v(
                      _vm._s(_vm.rssFeedsByUid[_vm.rssFeedUid].totalTracks) +
                        " published episodes"
                    ),
                  ]),
                  _vm.rssFeedsByUid[_vm.rssFeedUid].lastPublishedDate
                    ? _c("div", { staticClass: "mh3" }, [_vm._v("|")])
                    : _vm._e(),
                  _vm.rssFeedsByUid[_vm.rssFeedUid].lastPublishedDate
                    ? _c("div", { staticClass: "f6 fw1" }, [
                        _vm._v(
                          "\n        Latest episode published on\n        " +
                            _vm._s(
                              _vm.dateFormator(
                                _vm.rssFeedsByUid[_vm.rssFeedUid]
                                  .lastPublishedDate
                              )
                            ) +
                            "\n      "
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
              _vm.$permissions.isCreateEpisodeShowAllowed(_vm.rssFeedUid)
                ? _c("BaseButtonRed", {
                    staticStyle: { float: "right" },
                    attrs: {
                      icon: "add",
                      text: "Upload a new episode",
                      onClick: _vm.handleAddEpisodePodcast,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }