















































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { pluralize } from '@/utils/misc'
import { toReadableDate } from '@/utils/time'

@Component
export default class OverviewPodcastCard extends Vue {
  @Prop(Boolean) publish!: boolean
  @Prop(Boolean) isLargeCard!: boolean

  @Prop(Object) source!: any

  @Getter audio!: any
  @Getter tagPositions!: any
  @Getter rssFeedsByUid!: any
  @Getter rssFeedItemsByFeedUid!: any

  @Action getAudio!: any
  @Action showAddEpisode!: any
  @Action showAudioTrackPlayer!: any
  @Action showEditPodcast!: any

  @Action setSelectedRssFeedUid!: any

  isLoading = false

  get rssFeedUid() {
    return this.source.uid
  }
  get rssFeedDescription() {
    return this.rssFeedsByUid[this.rssFeedUid].description.replace(/(<([^>]+)>)/g, ' ')
  }

  tags(episode: any) {
    if (this.tagPositions(episode.uid) !== undefined) {
      const tagCount = this.tagPositions(episode.uid).length
      return `${tagCount} ${pluralize(tagCount, 'tag', 'tags')}`
    }
    return `0 tags`
  }

  dateFormator(date: number) {
    return toReadableDate(date)
  }

  truncate(str: string, count: number) {
    if (str.length < count) return str
    return str.substring(0, count) + '...'
  }

  handleEpisode(key: any) {
    this.$router.push('/audio-editor?uid=' + key)
  }

  handleAddEpisodePodcast() {
    this.showAddEpisode(this.rssFeedUid)
  }

  audioIsAdorified(episodeId: string) {
    return this.audio(episodeId) && this.audio(episodeId).adorificationStatus === 'FINISHED'
  }

  handleAudioClicked(episodeId: string) {
    if (!this.audioIsAdorified(episodeId)) {
      if (this.audio(episodeId).adorificationStatus && this.audio(episodeId).adorificationStatus !== 'FAILED') {
        this.handleEditAudio(episodeId)
      } else {
        this.handlePlayAudio(episodeId)
      }
    } else {
      this.gotoEpisode(episodeId)
    }
  }

  handlePlayAudio(episodeId: string) {
    this.showAudioTrackPlayer(episodeId)
  }

  handleEditAudio(episodeId: string) {
    this.$router.push('/audio-editor?uid=' + episodeId)
  }

  handlePodcastSelect() {
    if (this.publish) {
      this.$emit('podcastData', {
        uid: this.rssFeedUid,
        imgUrl: this.rssFeedsByUid[this.rssFeedUid].imageUrl,
      })
    } else this.$router.push(`/audio/podcasts/${this.rssFeedUid}`)
  }

  gotoEpisode(episodeId: string) {
    this.$router.push(`/audio-editor?uid=${episodeId}`)
  }

  handleEditPodcast(podcastUid: any) {
    this.showEditPodcast(podcastUid)
  }
}
