var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-wrap justify-between items-end mv3" },
    [
      _c(
        "div",
        { staticClass: "flex flex-wrap items-end" },
        [
          !_vm.goBack
            ? _c(
                "div",
                {
                  staticClass: "flex items-center",
                  class: _vm.large ? "w-lc" : "w-lc-small",
                },
                [
                  _vm.backIcon
                    ? _c(
                        "div",
                        {
                          staticClass: "flex items-center pointer dim",
                          on: {
                            click: function ($event) {
                              _vm.route
                                ? _vm.$router.push(_vm.route)
                                : _vm.$router.go(-1)
                            },
                          },
                        },
                        [
                          _c(
                            "i",
                            {
                              staticClass:
                                "material-icons f3 light-gray mr2 v-btm pointer",
                            },
                            [_vm._v("arrow_back")]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.type === "img"
                    ? _c("img", {
                        staticClass: "mr2",
                        attrs: {
                          src: _vm.svgIcon,
                          alt: "",
                          width: "50",
                          height: "50",
                        },
                      })
                    : _c(
                        "i",
                        {
                          staticClass: "material-icons f1 light-gray mr2 v-btm",
                        },
                        [_vm._v(_vm._s(_vm.icon))]
                      ),
                  _c(
                    "span",
                    { staticClass: "light-gray fw1", class: _vm.fontsize },
                    [_vm._v(_vm._s(_vm.title))]
                  ),
                ]
              )
            : _vm._e(),
          _vm.goBack
            ? _c("div", [
                _c(
                  "div",
                  {
                    staticClass: "link pointer dim mb2",
                    on: {
                      click: function ($event) {
                        _vm.route
                          ? _vm.$router.push(_vm.route)
                          : _vm.$router.go(-1)
                      },
                    },
                  },
                  [
                    _c(
                      "i",
                      { staticClass: "material-icons f3 light-gray mr2 v-btm" },
                      [_vm._v("arrow_back")]
                    ),
                    _c("span", { staticClass: "f4 light-gray fw1 mr4" }, [
                      _vm._v("Go back"),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._t("left"),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "flex flex-wrap items-end" },
        [_vm._t("right")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }