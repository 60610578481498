









import { Component, Vue } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { NETWORKROLES } from '@/constants/roles'
import AdoriService from '@/services/adori'

@Component
export default class TheMainPage extends Vue {
  @Getter profile!: any
  @Getter hasLoadedUser!: boolean
  @Getter isFirstTimeUser!: boolean
  @Getter currentNetwork!: any
  @Getter networkUsers!: any
  @Getter networkId!: any
  @Getter networkType!: any

  @Action loadUser!: any
  @Action showProfileEdit!: any
  @Action setFirstTimeUser!: any
  @Action getNetworkUsers!: any
  @Action showSignupEdit!: any

  async created() {
    if (!this.hasLoadedUser && !this.profile) {
      await this.loadUser()

      if (!this.networkUsers && this.currentNetwork.role.name === NETWORKROLES.owner) {
        await this.getNetworkUsers()
      }
    }
  }
}
