









































































































import { Component, Vue } from 'vue-property-decorator'
import TheMainPage from '@/components/Common/TheMainPage.vue'
import TheHeader from '@/components/Common/TheHeader.vue'
import TheSideNavigation from '@/components/Common/TheSideNavigation.vue'
import TheTitle from '@/components/Common/TheTitle.vue'
import OverviewTracks from '@/components/Overview/OverviewTracks.vue'
import OverviewPodcastCard from '@/components/Overview/OverviewPodcastCard.vue'
import OverviewBanner from '@/components/Overview/OverviewBanner.vue'
import OverviewInfoCard from '@/components/Overview/EmptyState/OverviewInfoCard.vue'
import moment from 'moment'
import banner_podcast_image_svg from '@/assets/podcast_image_svg.svg'
import get from 'lodash.get'
import { computed, SetupContext } from '@vue/composition-api'
import { useFetchRssFeed } from '@/hooks/rssFeeds'
import VirtualList from 'vue-virtual-scroll-list'
import { SUPPORT_STUDIO_MAIL } from '@/constants/env'
import { NETWORKROLES } from '@/constants/roles'
import { useAddNetworkUser, useGetNetworkUsers } from '@/hooks/network'

@Component({
  components: {
    TheMainPage,
    TheHeader,
    TheSideNavigation,
    TheTitle,
    OverviewTracks,
    OverviewPodcastCard,
    OverviewBanner,
    OverviewInfoCard,
    VirtualList,
  },
  setup(_, context: SetupContext) {
    const { data: rssFeedsData, isFetching: rssFeedLoading } = useFetchRssFeed(
      {
        limit: 200,
      },
      false,
      (data: any) => {
        context.root.$store.commit('setRssFeeds', data.data)
        context.root.$store.commit('setRssFeedsCount', data.count)
        context.root.$store.commit('setRssFeedsOffset', data.count)
        data?.data.map((rssFeed: any) =>
          context.root.$store.commit('addRssFeed', {
            rssFeed,
            rssFeedItems: [],
          })
        )
        context.root.$store.commit('setRssFeedUids')
      }
    )

    // Add Support User in the network code

    const isEnabled = computed(
      () =>
        [NETWORKROLES.owner, NETWORKROLES.admin].includes(context.root.$store.getters?.currentNetwork?.role?.name) ||
        context.root.$permissions.isManageNetworkBillingAllowed()
    )

    const { mutate: addNetworkUsers } = useAddNetworkUser({ email: SUPPORT_STUDIO_MAIL, role: NETWORKROLES.admin })
    useGetNetworkUsers({ enabled: isEnabled }, async (data: any) => {
      context.root.$store.commit('setUsers', data)
      const isSupportExist = data.data?.network_users.filter(
        (obj: any) => obj.role.name === NETWORKROLES.admin && obj.user.email === SUPPORT_STUDIO_MAIL
      )
      if (isSupportExist.length === 0 && context.root.$store.getters.email !== SUPPORT_STUDIO_MAIL) {
        addNetworkUsers()
      }
    })

    return {
      rssFeedsData,
      rssFeedLoading,
    }
  },
})
export default class ViewOverview extends Vue {
  bannerHeader: string = 'Create a new podcast'
  bannerSubHeader: string =
    'Manage and deliver unlimited podcast shows and episodes. <i>Adorify</i> your episodes with interactive tags to augment your story, and measure the jump in listener engagement and monetization opportunities!'
  bannerImageUrl: string = banner_podcast_image_svg

  rssFeedsData!: any
  rssFeedLoading!: boolean

  podcastSearchValue: string = ''
  podcastSortOrder: string = ''

  isSearching: boolean = false
  searchNumber: number = 0

  viewType: string = 'LIST_VIEW'

  get OverviewPodcastCard() {
    return OverviewPodcastCard
  }

  get isLargeCard() {
    return this.viewType === 'LIST_VIEW'
  }

  get rssFeedsCount() {
    return get(this.rssFeedsData, 'count', 0)
  }

  get rssFeedList() {
    return get(this.rssFeedsData, 'data', [])
  }

  get filteredList() {
    const list = [...this.rssFeedList]
    let filteredList = []
    switch (this.podcastSortOrder) {
      case 'latestCreated':
        filteredList = list.sort((a: any, b: any) => moment(a.createdOn).unix() - moment(a.createdOn).unix()).reverse()
        break
      case 'AZ':
        filteredList = list.sort((a: any, b: any) => (a.name.toLowerCase() >= b.name.toLowerCase() ? 1 : -1))
        break
      case 'ZA':
        filteredList = list.sort((a: any, b: any) => (a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1))
        break
      default:
        filteredList = list
    }
    if (this.podcastSearchValue) {
      return filteredList.filter((feed: any) => feed.name.toLowerCase().includes(this.podcastSearchValue.toLowerCase()))
    }
    return filteredList
  }

  setViewType(viewType: string) {
    this.viewType = viewType
  }
  setSortOrder(sortOrder: string) {
    this.podcastSortOrder = sortOrder
  }

  clearSearch() {
    this.podcastSearchValue = ''
  }
}
