






























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class TheTitle extends Vue {
  @Prop(String) icon!: string
  @Prop(String) title!: string
  @Prop(Boolean) goBack!: boolean
  @Prop(Boolean) large!: boolean
  @Prop(String) type!: string
  @Prop(String) src!: string
  @Prop(Boolean) backIcon!: boolean
  @Prop(String) svgIcon!: string
  @Prop(String) fontsize!: string
  @Prop(String) route!: string

  checkRouter() {
    console.log(this.$router)
  }
}
