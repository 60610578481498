var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "TheMainPage",
    [
      _c("TheHeader"),
      _c(
        "div",
        { staticClass: "flex flex-wrap" },
        [
          _c("TheSideNavigation"),
          _c(
            "div",
            { staticClass: "limiter scrollbar" },
            [
              _c("TheTitle", {
                attrs: { icon: "dashboard", title: "Overview" },
              }),
              _vm.rssFeedLoading || _vm.rssFeedsCount
                ? _c("div", { staticClass: "flex" }, [
                    _c("div", { staticClass: "w-60 flex flex-items" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.podcastSearchValue,
                            expression: "podcastSearchValue",
                          },
                        ],
                        ref: "_searchPodcasts",
                        staticClass:
                          "bg-adori-light-gray bw0 br2 pl3 pr5 pv3 f6 white w-100",
                        staticStyle: {
                          "margin-bottom": "24px",
                          border: "1px solid #3f3f3f",
                        },
                        attrs: { autofocus: "", placeholder: "Search shows" },
                        domProps: { value: _vm.podcastSearchValue },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.podcastSearchValue = $event.target.value
                          },
                        },
                      }),
                      _vm.podcastSearchValue.length === 0
                        ? _c(
                            "i",
                            {
                              staticClass: "material-icons silver relative",
                              staticStyle: { top: "13px", right: "31px" },
                            },
                            [_vm._v("search")]
                          )
                        : _c(
                            "i",
                            {
                              staticClass:
                                "material-icons silver relative pointer dim",
                              staticStyle: { top: "13px", right: "31px" },
                              on: { click: _vm.clearSearch },
                            },
                            [_vm._v("close")]
                          ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "flex flex-items w-40 justify-end" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "f6 mt3 mb4 ml4 pointer",
                            class:
                              _vm.podcastSortOrder === ""
                                ? "white bb bw1 b--white"
                                : "silver",
                            on: {
                              click: function ($event) {
                                return _vm.setSortOrder("")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            Latest episode published\n          "
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "f6 mt3 mb4 ml4 pointer",
                            class:
                              _vm.podcastSortOrder === "AZ"
                                ? "white bb bw1 b--white"
                                : "silver",
                            on: {
                              click: function ($event) {
                                return _vm.setSortOrder("AZ")
                              },
                            },
                          },
                          [_vm._v("\n            A-Z\n          ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "f6 mt3 mb4 ml4 pointer",
                            class:
                              _vm.podcastSortOrder === "ZA"
                                ? "white bb bw1 b--white"
                                : "silver",
                            on: {
                              click: function ($event) {
                                return _vm.setSortOrder("ZA")
                              },
                            },
                          },
                          [_vm._v("\n            Z-A\n          ")]
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm.rssFeedLoading
                ? _c(
                    "div",
                    _vm._l(new Array(10), function (_, index) {
                      return _c("div", {
                        key: index,
                        staticClass:
                          "w-100 bg-adori-light-gray bb bw1 b--adori-gray flex justify-center items-center skeleton cus-size mt2",
                      })
                    }),
                    0
                  )
                : _c("div", [
                    _vm.rssFeedsCount === 0
                      ? _c(
                          "div",
                          [
                            _c("OverviewBanner", {
                              attrs: {
                                isLargeBanner: "",
                                bannerHeader: _vm.bannerHeader,
                                bannerSubHeader: _vm.bannerSubHeader,
                                bannerImageUrl: _vm.bannerImageUrl,
                              },
                            }),
                            _c("OverviewInfoCard", { attrs: { isColumn: "" } }),
                          ],
                          1
                        )
                      : _vm.filteredList.length === 0
                      ? _c("div", { staticClass: "silver pt2 pb4" }, [
                          _vm._v(
                            "\n          No show results found! Try searching with a complete word.\n        "
                          ),
                        ])
                      : _c(
                          "div",
                          [
                            _c("VirtualList", {
                              attrs: {
                                "data-key": "uid",
                                "data-sources": _vm.filteredList,
                                "data-component": _vm.OverviewPodcastCard,
                                "estimate-size": 20,
                                "extra-props": {
                                  isLargeCard: _vm.isLargeCard,
                                },
                                keeps: 20,
                              },
                            }),
                          ],
                          1
                        ),
                  ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }