var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-100 ba b--adori-gray" }, [
    _c(
      "div",
      {
        staticClass: "f3 light-gray",
        staticStyle: { "margin-bottom": "1.5rem" },
      },
      [
        _vm._v("\n    Recent tracks\n    "),
        _c("BaseButtonRed", {
          staticClass: "fr",
          class: !_vm.hasRecentTracks ? "dn" : "",
          attrs: { text: "View all tracks", onClick: _vm.displayAudio },
        }),
      ],
      1
    ),
    _vm.isLoading
      ? _c("div", { staticClass: "flex justify-center items-center" }, [
          _c("div", { staticClass: "mr3" }, [_vm._v("Loading tracks")]),
          _c("div", { staticClass: "loader loader-inline" }),
        ])
      : !_vm.hasRecentTracks
      ? _c(
          "div",
          [
            _c("div", { staticClass: "f4 silver bt b--adori-gray bw1 pv3" }, [
              _vm._v("Upload your first audio track."),
            ]),
            _c("BaseButtonRed", {
              attrs: {
                text: "Upload a new track",
                onClick: _vm.onUploadEpisode,
              },
            }),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "cf bb b--adori-gray bw1" },
          _vm._l(_vm.recentTracks, function (id) {
            return _c(
              "div",
              {
                key: id,
                staticClass:
                  "flex justify-start items-center pv3 link pointer darker-hover bt b--adori-gray bw1",
                on: {
                  click: function () {
                    return _vm.handleAudioClicked(id)
                  },
                },
              },
              [
                _c("div", { staticClass: "w-img bg-black h1 tc mr2" }, [
                  _vm.episodeImageThumbnail(id)
                    ? _c("img", {
                        staticClass: "h-img",
                        attrs: { src: _vm.episodeImageThumbnail(id) },
                      })
                    : _c(
                        "i",
                        {
                          staticClass:
                            "material-icons light-gray f1 br2 bg-adori-very-light-gray w2 mr3 v-mid",
                        },
                        [_vm._v(" audiotrack ")]
                      ),
                ]),
                _c(
                  "div",
                  { staticClass: "flex w-100 items-center justify-between" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "w-70 pr4 flex items-center custom-fonts",
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.truncate(_vm.episodeName(id), 50)) +
                            "\n        "
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "w-30 silver truncate f6 tr" }, [
                      _vm.audioIsAdorified(id)
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.episodeTagsText(id)) + " "),
                          ])
                        : _c("span", [_vm._v("Not interactive")]),
                      _vm._v(
                        "\n          · " +
                          _vm._s(_vm.episodeDate(id)) +
                          "\n        "
                      ),
                    ]),
                  ]
                ),
              ]
            )
          }),
          0
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }