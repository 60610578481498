var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasLoadedUser
    ? _c("div", [_vm._t("default")], 2)
    : _c(
        "div",
        { staticClass: "w-100 min-vh-100 flex justify-center items-center" },
        [_c("div", { staticClass: "loader" })]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }