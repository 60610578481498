












































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { toReadableDate } from '@/utils/time'
import { pluralize } from '@/utils/misc'
import get from 'lodash.get'

@Component
export default class OverviewTracks extends Vue {
  @Getter audioUids!: any
  @Getter audio!: any
  @Getter tagPositions!: any
  @Action getAudio!: any
  @Action uploadNewAudioTrack!: any
  @Action showAudioTrackPlayer!: any

  isLoading: boolean = true

  async created() {
    if (this.hasRecentTracks) {
      for (let i = 0; i < this.recentTracks.length; i++) {
        const id = this.recentTracks[i]
        if (!this.audio(id)) {
          await this.getAudio(id)
        }
      }
    }
    this.isLoading = false
  }

  get recentTracks() {
    return this.audioUids.slice(0, 5)
  }

  get hasRecentTracks() {
    return this.recentTracks.length !== 0
  }

  audioIsAdorified(episodeId: string) {
    return this.audio(episodeId) && this.audio(episodeId).adorificationStatus === 'FINISHED'
  }

  handleAudioClicked(episodeId: string) {
    if (!this.audioIsAdorified(episodeId)) {
      if (this.audio.adorificationStatus && this.audio.adorificationStatus !== 'FAILED') {
        this.handleEditAudio(episodeId)
      } else {
        this.handlePlayAudio(episodeId)
      }
    } else {
      this.gotoEpisode(episodeId)
    }
  }

  handlePlayAudio(episodeId: string) {
    this.showAudioTrackPlayer(episodeId)
  }

  handleEditAudio(episodeId: string) {
    this.$router.push('/audio-editor?uid=' + episodeId)
  }

  displayAudio() {
    this.$router.push({ path: 'audio' })
  }

  episodeName(episodeId: string) {
    return this.audio(episodeId).name
  }

  episodeDate(episodeId: string) {
    if (this.audio(episodeId).publishTime) {
      return toReadableDate(this.audio(episodeId).publishTime)
    }
    return toReadableDate(this.audio(episodeId).createdOn)
  }

  episodeTagsCount(episodeId: string) {
    if (!this.tagPositions(episodeId)) {
      return 0
    }
    return this.tagPositions(episodeId) && this.tagPositions(episodeId).length
  }

  episodeTagsText(episodeId: string) {
    const count = this.episodeTagsCount(episodeId)
    return `${count} ${pluralize(count, 'tag', 'tags')}`
  }

  episodeImageThumbnail(episodeId: string) {
    return this.audio(episodeId).imageInfo.thumbnailURL || ''
  }

  gotoEpisode(episodeId: string) {
    this.$router.push(`/audio-editor?uid=${episodeId}`)
  }

  onUploadEpisode() {
    this.uploadNewAudioTrack()
    this.$router.push('/audio')
  }

  truncate(str: string, count: number) {
    if (str.length < count) return str
    return str.substring(0, count) + '...'
  }
}
